import { filteredExtraItems } from '../MenuItemForm/filterFunctions';

import OrderItem from '../../molecules/OrderItem/OrderItem';

const Step5 = ({ allItems, orderMainMenuItem }) => {
  const allExtraMeals = filteredExtraItems(allItems, [orderMainMenuItem]);

  return (
    <>
      <h4 className="p-order__heading">Please choose an extra dish!</h4>
      <div className="row row__large p-order__menu-wrapper">
        {allExtraMeals.length > 0 ? (
          <div className="p-order__foods">
            <OrderItem items={allExtraMeals} type="extra" />
          </div>
        ) : (
          <h4>No extra items for the selected menu item found</h4>
        )}
      </div>
    </>
  );
};

export default Step5;
