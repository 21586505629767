export const workdays = [
  { label: 'Mon', name: 'monday' },
  { label: 'Tue', name: 'tuesday' },
  { label: 'Wed', name: 'wednesday' },
  { label: 'Thu', name: 'thursday' },
  { label: 'Fri', name: 'friday' },
];

export const week = [
  ...workdays,
  { label: 'Sat', name: 'saturday' },
  { label: 'Sun', name: 'sunday' },
];

export const menuItemTypes = [
  { value: 'MAIN', label: 'Main' },
  { value: 'SIDE', label: 'Side' },
  { value: 'EXTRA', label: 'Extra' },
];

export const userRoleTypes = [
  { value: 'USER', label: 'User' },
  { value: 'ADMIN', label: 'Admin' },
];

export const companies = [
  { value: 'Valtech', label: 'Valtech' },
  { value: 'KinCarta', label: 'Kin + Carta' },
];
