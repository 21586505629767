import instance from './setupAxios';

export const getAllUserOrders = async () => {
  return await instance.get('order/userOrders');
};

export const getAllUserOrdersPaginated = (
  numberOfPage = 0,
  pageSize = 12,
  dateFrom,
  dateTo,
  restaurantId,
  userId,
  company,
  status
) => {
  const params = {
    dateFrom,
    dateTo,
    userId,
    restaurantId,
    company,
    status,
    offset: numberOfPage,
    pageSize,
    field: 'id',
    direction: 'DESC',
  };

  return instance.get('order/userOrders', { params });
};

export const getCurrentUserOrders = async (userId) => {
  return await instance.get(`order/myOrders`);
};

export const getCurrentUserTotalCost = async (userId, dateFrom, dateTo) => {
  const response = await instance.get(`order/myCost`, {
    params: { userId, dateFrom, dateTo },
  });
  return response.data; // Needs update to REDUX
};
export const getCurrentUserOrdersPaginated = async (
  numberOfPage = 0,
  pageSize = 12,
  userId,
  dateFrom,
  dateTo
) => {
  const params = {
    offset: numberOfPage,
    pageSize,
    userId,
    ...(dateFrom && { dateFrom }),
    ...(dateTo && { dateTo }),
  };

  return instance.get('order/myOrders', { params });
};

export const getPopularOrders = async () => {
  return await instance.get(`order/popularOrders`);
};

export const getFavoriteUserOrders = async () => {
  return await instance.get(`order/myFavoriteOrders`);
};

export const createOrder = async (order) => {
  return await instance.post('order', order);
};

export const cancelOrder = async (userOrderId) => {
  return await instance.post(`order/userOrder/${userOrderId}/cancel`);
};

const OrderService = {
  createOrder,
  cancelOrder,
  getAllUserOrders,
  getPopularOrders,
  getFavoriteUserOrders,
  getCurrentUserOrders,
  getAllUserOrdersPaginated,
  getCurrentUserOrdersPaginated,
};

export default OrderService;
