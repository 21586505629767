import { useNavigate } from 'react-router-dom';

import { IconBurger } from '../../atoms/Icons';

import './OrderCard.scss';

const OrderCard = ({ order }) => {
  const { restaurantName, restaurantId, menuItemDto } = order;
  const navigate = useNavigate();

  const handlePopularOrderClick = () => {
    navigate(
      `/order?restaurantId=${restaurantId}&menuItemId=${menuItemDto.menuItemId}`
    );
  };

  return (
    <div className="popular-orders_card" onClick={handlePopularOrderClick}>
      <h5>{restaurantName}</h5>
      <ul className="popular-orders_description">
        <li>
          <strong>Main Item</strong>
          <p>{menuItemDto.name}</p>
        </li>
        <li>
          <strong>Cost</strong>
          <p>{menuItemDto.costForEmployees}</p>
        </li>
        <div className="popular-orders_buttons-wrapper">
          <button className="popular-orders_button">
            <IconBurger />
          </button>
        </div>
      </ul>
    </div>
  );
};

export default OrderCard;
