import React, { useState } from 'react';

import {
  monthNames,
  weekdays,
  today,
  days,
  firstDayOfMonth,
  isDisabled,
  newSelectedDate,
  diffInDays,
  isSameMonth,
  getDayClassNames,
} from './Calendar.util';
import './calendar.scss';

const Calendar = ({ currentDate, setCurrentDate, limitDays }) => {
  const [selectedDay, setSelectedDay] = useState(currentDate.getDate());
  const [nextMonthDisabled, setNextMonthDisabled] = useState(false);
  const setNewDate = (offSet, day = 1) => {
    const newDate = newSelectedDate(currentDate, offSet, day);
    if (
      newDate < today.setHours(0, 0, 0, 0) ||
      diffInDays(newDate) >= limitDays
    ) {
      return;
    }

    setCurrentDate(newDate);
    setSelectedDay(day);
  };

  let tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);

  const setPrevMonth = () => {
    setNextMonthDisabled(false);
    setNewDate(-1, tomorrow.getDate());
  };

  const setNextMonth = () => {
    setNextMonthDisabled(true);
    setNewDate(+1);
  };

  return (
    <div className="calendar">
      <div className="calendar__header">
        <button
          className="calendar__header-btn"
          onClick={setPrevMonth}
          disabled={today.getMonth() === currentDate.getMonth()}
        >
          Prev
        </button>
        <h5 className="calendar__header-title">
          {monthNames[currentDate.getMonth()]} {currentDate.getFullYear()}
        </h5>
        <button
          className="calendar__header-btn"
          onClick={setNextMonth}
          disabled={isSameMonth(limitDays) || nextMonthDisabled}
        >
          Next
        </button>
      </div>
      <div className="calendar__weekdays">
        {weekdays.map((day) => (
          <div key={day} className="calendar__weekday">
            {day}
          </div>
        ))}
      </div>
      <div className="calendar__days">
        {Array.from({ length: firstDayOfMonth(currentDate) }, (_, i) => (
          <div key={i} className="calendar__day-empty"></div>
        ))}
        {days(currentDate).map((day) => {
          const dayClassNames = getDayClassNames(
            currentDate,
            selectedDay,
            day,
            isDisabled,
            limitDays
          );
          return (
            <div
              key={day}
              className={dayClassNames}
              onClick={() => setNewDate(0, day)}
            >
              {day}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Calendar;
