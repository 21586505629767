import { ORDERS_ACTION_TYPES } from './orders.types';

export const INITIAL_STATE = {
  orders: [],
  total: 0,
  filteredOrders: undefined,
  isLoading: false,
  selectedOrder: undefined,
  selectedOrderId: undefined,
  selectedMenuItem: null,
  selectedSideMenuItem: null,
  selectedExtraMenuItems: [],
  selectedLocation: undefined,
  selectedOrderDate: undefined,
  shouldSendEmail: true,
  allSelectedItems: [],
  currentOrderComment: undefined,
  createdOrderSuccessfully: false,
  ordersForDate: [],
  currentUserOrders: [],
};

export const ordersReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case ORDERS_ACTION_TYPES.FETCH_ORDERS_START:
      return { ...state, isLoading: true };
    case ORDERS_ACTION_TYPES.FETCH_ORDERS_SUCCESS:
      return {
        ...state,
        orders: payload.content,
        total: payload.totalElements,
      };
    case ORDERS_ACTION_TYPES.FETCH_ORDERS_END:
      return { ...state, isLoading: false };
    case ORDERS_ACTION_TYPES.SET_SELECTED_SIDE_MENU_ITEM:
      return { ...state, selectedSideMenuItem: payload };
    case ORDERS_ACTION_TYPES.SET_SELECTED_EXTRA_MENU_ITEM:
      return { ...state, selectedExtraMenuItems: payload };
    case ORDERS_ACTION_TYPES.SET_SELECTED_MENU_ITEM:
      return { ...state, selectedMenuItem: payload };
    case ORDERS_ACTION_TYPES.FETCH_SELECTED_ORDER_SUCCESS:
      return { ...state, selectedOrder: payload };
    case ORDERS_ACTION_TYPES.FETCH_POPULAR_ORDERS_SUCCESS:
      return { ...state, popularOrders: payload };
    case ORDERS_ACTION_TYPES.FETCH_FAVORITE_USER_ORDERS_SUCCESS:
      return { ...state, favoriteUserOrders: payload };
    case ORDERS_ACTION_TYPES.FETCH_CREATED_ORDER_SUCCESSFULLY:
      return { ...state, createdOrderSuccessfully: true };
    case ORDERS_ACTION_TYPES.FETCH_ORDERS_FOR_DATE:
      return { ...state, ordersForDate: payload };
    case ORDERS_ACTION_TYPES.SET_ORDER_COMMENT:
      return { ...state, currentOrderComment: payload };
    case ORDERS_ACTION_TYPES.SET_ALL_SELECTED_ITEMS:
      return { ...state, allSelectedItems: payload };
    case ORDERS_ACTION_TYPES.SET_SELECTED_ORDER_DATE:
      return { ...state, selectedOrderDate: payload };
    case ORDERS_ACTION_TYPES.SET_SHOULD_SEND_EMAIL:
      return { ...state, shouldSendEmail: payload };
    case ORDERS_ACTION_TYPES.SET_TERMS_ACCEPTED:
      return { ...state, termsAccepted: payload };
    case ORDERS_ACTION_TYPES.SET_FILTERED_ORDERS:
      return { ...state, filteredOrders: payload };
    case ORDERS_ACTION_TYPES.FETCH_CURRENT_USER_ORDERS_SUCCESS:
      return { ...state, currentUserOrders: payload };
    case ORDERS_ACTION_TYPES.CANCEL_ORDER_END:
      return { ...state, isLoading: false };
    case ORDERS_ACTION_TYPES.CLEAR_CURRENT_ORDER:
      return INITIAL_STATE;

    default:
      return state;
  }
};
