import { week } from '../../../utils/options';

const filterMenuItemsByType = (allMenuItems, type) =>
  allMenuItems.filter((menuItem) => menuItem.type === type);

export const mainMenuList = (allMenuItems) =>
  filterMenuItemsByType(allMenuItems, 'MAIN');

const sideMenuList = (allMenuItems) =>
  filterMenuItemsByType(allMenuItems, 'SIDE');

const extraMenuList = (allMenuItems) =>
  filterMenuItemsByType(allMenuItems, 'EXTRA');

export const activeMenuList = (allMenuItems) =>
  allMenuItems.filter((item) => item.status === 'ACTIVE');

export const filterMainMenuItemsByCategory = (allMenuItems, categoryId) =>
  mainMenuList(allMenuItems).filter((item) =>
    item.categoryIds.includes(categoryId)
  );

export const filterMainMenuItemsByDate = (allMenuItems, date) => {
  const dayName = getDayNameFromDate(date);
  return mainMenuList(allMenuItems).filter((item) => item.workingDays[dayName]);
};

export const filterMainMenuItemsByCategoryAndDate = (
  allMenuItems,
  categoryId,
  date
) => {
  const dayName = getDayNameFromDate(date);
  return mainMenuList(allMenuItems).filter(
    (item) => item.categoryIds.includes(categoryId) && item.workingDays[dayName]
  );
};

export const filteredSideItems = (allMenuItems, mainItems) =>
  sideMenuList(allMenuItems).filter((sideItem) =>
    mainItems.some((mainItem) =>
      sideItem.parentMenuItems.includes(mainItem.menuItemId)
    )
  );

export const filteredExtraItems = (allMenuItems, mainItems) =>
  extraMenuList(allMenuItems).filter((extraItem) =>
    mainItems.some(
      (mainItem) =>
        extraItem.parentMenuItems.includes(mainItem.menuItemId) ||
        extraItem.parentMenuItems.length === 0
    )
  );

const getDayNameFromDate = (date) => {
  const selectedDay = date.substring(0, 3);
  const day = week.find((day) => day.label === selectedDay);
  return day.name;
};
