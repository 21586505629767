import React from 'react';
import { Link } from 'react-router-dom';

import CategoryRow from '../CategoryRow/CategoryRow';

import Button from '../../atoms/Button/Button';

import './AllCategoriesTable.scss';

const AllCategoriesTable = ({ categoriesList }) => {
  return (
    <div className="wrapper">
      {categoriesList.length ? (
        <div className="table" data-testid="all-categories--table">
          <div className="table__row table__header">
            <div className="table__cell id">ID</div>
            <div className="table__cell title">Title</div>
            <div className="table__cell buttons"></div>
          </div>
          <div className="table__body">
            {categoriesList?.map((category) => (
              <CategoryRow category={category} key={category.id} />
            ))}
          </div>
        </div>
      ) : (
        <h4>No Categories</h4>
      )}
      <div className="table__button-group">
        <Link to={`/restaurant`}>
          <Button
            icon="arrow-left"
            hoverText="go back"
            classes="hover-bg--black"
          />
        </Link>

        <Link to={`/category/create`}>
          <Button
            classes="bg--blue hover-bg--black"
            text="Add new"
            hoverText="+"
          />
        </Link>
      </div>
    </div>
  );
};

export default AllCategoriesTable;
