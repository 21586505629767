import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

import { isAdmin } from '../../../store/currentUser/currentUser.selector';
import { removeSelectedRestaurant } from '../../../store/restaurants/restaurants.actions';
import { restaurants } from '../../../store/restaurants/restaurants.selector';
import {
  fetchRestaurantsAsync,
  getLocationsAsync,
} from '../../../store/restaurants/restaurants.thunk';
import Button from '../../atoms/Button/Button';
import RestaurantList from '../../molecules/RestaurantList/RestaurantList';

import './AllRestaurants.scss';

const AllRestaurants = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const admin = useSelector(isAdmin);
  const allRestaurants = useSelector(restaurants);

  useEffect(() => {
    dispatch(getLocationsAsync());
    dispatch(fetchRestaurantsAsync());
  }, []); // eslint-disable-line

  function navigateToCreateRestaurant() {
    dispatch(removeSelectedRestaurant());
    navigate('/restaurant/create/');
  }

  return (
    <>
      <div className="p-all-restaurants row">
        <div className="col__desktop-6 col__laptop-6 col__tablet-12 col__mobile-12">
          <h2 className="p-all-restaurants__heading">
            Places To Eat{' '}
            <span className="p-all-restaurants__heading--light">
              (All Restaurants)
            </span>
          </h2>
        </div>
        {admin && (
          <>
            <div className="col__desktop-6 col__laptop-6 col__tablet-12 col__mobile-12 p-all-restaurants__action">
              <Button
                text="add restaurant"
                hoverText="+"
                classes="bg--blue hover-bg--black large slim"
                onClick={navigateToCreateRestaurant}
              />
            </div>
            <div className="col__desktop-6 col__laptop-6 col__tablet-12 col__mobile-12 p-all-restaurants__action">
              <Link to={`/categories`}>
                <Button
                  text="manage category"
                  hoverText="+"
                  classes="bg--blue hover-bg--black large slim"
                />
              </Link>
            </div>
          </>
        )}
      </div>

      <RestaurantList restaurants={allRestaurants} />
    </>
  );
};

export default AllRestaurants;
