import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Form from '../Form/Form';
import Modal from '../Modal/Modal';

import { INVALID_INPUT } from '../../../constants/notification-messages';
import { REGEX_INPUT } from '../../../constants/regex-patterns';
import { isRegisteredSuccessfully } from '../../../store/currentUser/currentUser.selector';
import { registerAsync } from '../../../store/currentUser/currentUser.thunk';
import { companies } from '../../../utils/options';
import Button from '../../atoms/Button/Button';
import Input from '../../atoms/Input/Input';
import SelectInput from '../../atoms/SelectInput/SelectInput';

import './newAccountRequest.scss';

const NewAccountRequest = ({ showModal, setShowModal }) => {
  const dispatch = useDispatch();
  const registeredSuccessfully = useSelector(isRegisteredSuccessfully);

  useEffect(() => {
    if (registeredSuccessfully) {
      setShowModal(false);
    }
  }, [registeredSuccessfully]); //eslint-disable-line

  const formInputs = {
    firstName: {
      name: 'firstName',
      placeholder: 'Your first name',
      type: 'text',
      validation: {
        pattern: REGEX_INPUT.NAME,
        patternMessage: INVALID_INPUT.NAME,
        maxLength: 100,
        maxLengthMessage: INVALID_INPUT.GENERAL_LENGTH,
      },
    },
    lastName: {
      name: 'lastName',
      placeholder: 'Your last name',
      type: 'text',
      validation: {
        pattern: REGEX_INPUT.NAME,
        patternMessage: INVALID_INPUT.NAME,
        maxLength: 100,
        maxLengthMessage: INVALID_INPUT.GENERAL_LENGTH,
      },
    },
    email: {
      name: 'email',
      placeholder: 'Your email',
      type: 'email',
      validation: {
        pattern: REGEX_INPUT.VALTECH_MAIL,
        patternMessage: INVALID_INPUT.VALTECH_MAIL,
      },
    },
    cardId: {
      name: 'cardId',
      placeholder: 'Your Card ID',
      type: 'text',
      validation: {
        pattern: REGEX_INPUT.NUMBER,
        patternMessage: INVALID_INPUT.NUMBER,
        maxLength: 10,
        maxLengthMessage: INVALID_INPUT.NUMBER,
      },
    },
  };

  const formTitle = 'Request New Account';
  const formDisclaimer = `By clicking the Submit button above, I hereby agree to and accept the
  terms and conditions governing my use of the Valtech Gourmet website.`;

  const handleSubmitForm = (data) => {
    const { firstName, lastName, email, cardId, company } = data;
    dispatch(registerAsync(firstName, lastName, email, cardId, company));
  };

  return (
    <>
      {setShowModal && (
        <Modal
          showModal={showModal}
          setShowModal={setShowModal}
          hasBannerAlt
          hasBorder
          disclaimer={formDisclaimer}
          hasDisclaimerAlt
          title={formTitle}
        >
          <Form onSubmit={handleSubmitForm}>
            <div className="input-container">
              {Object.values(formInputs).map((input, index) => (
                <Input
                  key={index}
                  className="normal-grey"
                  id={input.name}
                  name={input.name}
                  placeholder={input.placeholder}
                  required
                  type={input.type}
                  validation={input.validation}
                />
              ))}
              <SelectInput
                label="Entity"
                name="company"
                options={companies}
                classNames={'a-select-input a-select-input--company'}
              />
            </div>
            <div className="input-container buttons">
              <Button
                type="submit"
                text="submit"
                classes="bg--blue hover-bg--black"
              />
              <Button
                type="reset"
                text="clear"
                classes="bg--white hover-bg--black"
              />
            </div>
          </Form>
        </Modal>
      )}
    </>
  );
};

export default NewAccountRequest;
