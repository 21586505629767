import OrderCard from '../../molecules/OrderCard/OrderCard';

const PopularOrders = ({ orderList, listTitle }) => {
  return (
    <>
      <h2 className="popular-orders_title">{listTitle}</h2>
      <div className="popular-orders_wrapper">
        {orderList.map((order) => (
          <OrderCard order={order} key={order.menuItemId} />
        ))}
      </div>
    </>
  );
};

export default PopularOrders;
