import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Routes, Route } from 'react-router-dom';

import './app.scss';
import AdminOutlet from './components/Outlets/AdminOutlet';
import LoginOutlet from './components/Outlets/LoginOutlet';
import PrivateOutlet from './components/Outlets/PrivateOutlet';
import AccountRequests from './components/Pages/AccountRequests/AccountRequests';
import AllCategories from './components/Pages/AllCategories/AllCategories';
import AllRestaurants from './components/Pages/AllRestaurants/AllRestaurants';
import AllUsers from './components/Pages/AllUsers/AllUsers';
import CategoryEdit from './components/Pages/CategoryEdit/CategoryEdit';
import CategoryForm from './components/Pages/CategoryForm/CategoryForm';
import ConfirmEmail from './components/Pages/ConfirmEmail/ConfirmEmail';
import CreateNewUser from './components/Pages/CreateNewUser/CreateNewUser';
import Dashboard from './components/Pages/Dashboard/Dashboard';
import EditProfile from './components/Pages/EditProfile/EditProfile';
import ForgotPassword from './components/Pages/ForgotPassword/ForgotPassword';
import Login from './components/Pages/Login/Login';
import MenuItemForm from './components/Pages/MenuItemForm/MenuItemForm';
import Order from './components/Pages/Order/Order';
import OrderHistory from './components/Pages/OrderHistory/OrderHistory';
import Restaurant from './components/Pages/Restaurant/Restaurant';
import RestaurantForm from './components/Pages/RestaurantForm/RestaurantForm';
import SetNewPassword from './components/Pages/SetNewPassword/SetNewPassword';
import User from './components/Pages/User/User';
import Notification from './components/atoms/Notifications/Notification';
import Spinner from './components/atoms/Spinner/Spinner';
import ErrorComponent from './components/molecules/ErrorComponent/ErrorComponent';
import Navbar from './components/molecules/Navbar/Navbar';
import { Loading } from './store/core.selector';
import { currentUser } from './store/currentUser/currentUser.selector';
import { refreshTokenAsync } from './store/currentUser/currentUser.thunk';
import RefreshAndRemoveToken from './utils/refreshAndRemoveToken';

const App = () => {
  const loading = Loading();
  const user = useSelector(currentUser);
  const dispatch = useDispatch();

  useEffect(() => {
    if (user) {
      (async () => {
        dispatch(refreshTokenAsync());
      })();
    }
  }, []); //eslint-disable-line

  return (
    <div className="App" data-testid="app-element">
      <header className="App-header">
        <Navbar />
      </header>
      {loading && <Spinner />}
      <Notification />
      <RefreshAndRemoveToken />
      <Routes>
        <Route path="set-new-password" element={<SetNewPassword />} />
        <Route path="confirm-email" element={<ConfirmEmail />} />
        <Route element={<LoginOutlet />}>
          <Route path="forgot-password" element={<ForgotPassword />} />
          <Route path="/" element={<Login />} />
        </Route>
        <Route element={<PrivateOutlet />}>
          <Route path="dashboard" element={<Dashboard />} />
          <Route path="order" element={<Order />} />
          <Route path="restaurant" element={<AllRestaurants />} />
          <Route path="restaurant/:restaurantId" element={<Restaurant />} />
          <Route path="user-profile/:userId" element={<User />} />
          <Route path="edit-profile/:userId" element={<EditProfile />} />
          <Route path="error-page" element={<ErrorComponent />} />
          <Route element={<AdminOutlet />}>
            <Route path="all-users" element={<AllUsers />} />
            <Route path="create-menu-item" element={<MenuItemForm />} />
            <Route
              path="edit-menu-item/:menuItemId"
              element={<MenuItemForm />}
            />
            <Route path="categories" element={<AllCategories />} />
            <Route path="category/create" element={<CategoryForm />} />
            <Route
              path="category/:categoryId/edit"
              element={<CategoryEdit />}
            />
            <Route path="restaurant/create" element={<RestaurantForm />} />
            <Route
              path="restaurant/:restaurantId/edit"
              element={<RestaurantForm />}
            />
            <Route path="create-new-user" element={<CreateNewUser />} />
            <Route path="account-requests" element={<AccountRequests />} />
            <Route path="order-history" element={<OrderHistory />} />
          </Route>
        </Route>
        <Route
          path="*"
          element={
            <ErrorComponent
              errorType={404}
              errorTitle="Oops! Page not found"
              errorDesc="Sorry, but the page you are looking for is not found. Please, make sure you have typed the correct URL."
            />
          }
        />
      </Routes>
    </div>
  );
};

export default App;
