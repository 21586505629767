import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { categories } from '../../../store/restaurants/restaurants.selector';
import { getCategoriesAsync } from '../../../store/restaurants/restaurants.thunk';
import AllCategoriesTable from '../../molecules/AllCategoriesTable/AllCategoriesTable.jsx';

const AllCategories = () => {
  const allCategories = useSelector(categories);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCategoriesAsync());
  }, []); //eslint-disable-line

  return (
    <div className="row category-row">
      <div className="col__desktop-10 col__laptop-10 p-account-request">
        <h1 className="p-account-request__main-heading" data-testid="header">
          All Categories
        </h1>
        <AllCategoriesTable categoriesList={allCategories} />
      </div>
    </div>
  );
};

export default AllCategories;
