import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import { removeCategoryAsync } from '../../../store/restaurants/restaurants.thunk';
import Button from '../../atoms/Button/Button';
import Modal from '../../molecules/Modal/Modal';

const CategoryRow = ({ category }) => {
  const { id, name, linked } = category;
  const dispatch = useDispatch();
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const removeCategory = () => {
    dispatch(removeCategoryAsync(id));
    setShowDeleteModal(false);
  };

  return (
    <div className="table__row">
      <div
        className="table__cell id"
        data-testid="account-request--id"
        data-label="ID"
      >
        <p className="cell__data">{id}</p>
      </div>
      <div
        className="table__cell name"
        data-testid="account-request--name"
        data-label="Title"
      >
        <h6 className="cell__data">{name}</h6>
      </div>
      <div data-testid="buttons" className="table__cell buttons">
        <Link to={`/category/${category.id}/edit`}>
          <Button
            testID="button__cancel"
            text="edit"
            classes="bg--black hover-bg--green large no-border"
          />
        </Link>

        <Button
          testID="button__approved"
          onClick={() => setShowDeleteModal(true)}
          icon="close"
          hoverIcon="close"
          classes="bg--black hover-bg--red small no-border"
        />
      </div>

      {showDeleteModal && (
        <div className="p-restaurant__modal">
          <Modal
            showModal={showDeleteModal}
            setShowModal={setShowDeleteModal}
            hasBorder={true}
            hasBanner={true}
            title={
              linked
                ? 'This category is linked with menu items. Are you sure ?'
                : 'The category will be removed. Are you sure ?'
            }
          >
            <Button
              classes="bg--blue slim square small"
              text="Yes"
              onClick={removeCategory}
            />
            <Button
              classes="bg--blue slim square small"
              text="No"
              onClick={() => setShowDeleteModal(false)}
            />
          </Modal>
        </div>
      )}
    </div>
  );
};

export default CategoryRow;
