import { createAction } from '@reduxjs/toolkit';

import { RESTAURANTS_ACTION_TYPES } from '../restaurants/restaurants.types';

import { ORDERS_ACTION_TYPES } from './orders.types';

export const fetchOrdersStart = () => {
  return createAction(ORDERS_ACTION_TYPES.FETCH_ORDERS_START);
};

export const cancelUserOrderStart = () => {
  return createAction(ORDERS_ACTION_TYPES.CANCEL_ORDER_START);
};

export const cancelUserOrderSuccess = () => {
  return createAction(ORDERS_ACTION_TYPES.CANCEL_ORDER_SUCCESS);
};

export const cancelUserOrderEnd = () => {
  return createAction(ORDERS_ACTION_TYPES.CANCEL_ORDER_END);
};

export const fetchCurrentUserOrdersSuccess = (orders) => {
  return createAction(
    ORDERS_ACTION_TYPES.FETCH_CURRENT_USER_ORDERS_SUCCESS,
    () => ({
      payload: orders,
    })
  );
};

export const fetchPopularOrdersSuccess = (orders) => {
  return createAction(ORDERS_ACTION_TYPES.FETCH_POPULAR_ORDERS_SUCCESS, () => ({
    payload: orders,
  }));
};

export const fetchFavoriteUserOrdersSuccess = (orders) => {
  return createAction(
    ORDERS_ACTION_TYPES.FETCH_FAVORITE_USER_ORDERS_SUCCESS,
    () => ({
      payload: orders,
    })
  );
};

export const fetchOrdersSuccess = (orders) => {
  return createAction(ORDERS_ACTION_TYPES.FETCH_ORDERS_SUCCESS, () => ({
    payload: orders,
  }));
};

export const fetchSelectedOrderSuccess = (order) => {
  return createAction(ORDERS_ACTION_TYPES.FETCH_SELECTED_ORDER_SUCCESS, () => {
    return { payload: order };
  });
};

export const successfullyCreatedOrder = () => {
  return createAction(ORDERS_ACTION_TYPES.FETCH_CREATED_ORDER_SUCCESSFULLY);
};

export const fetchOrdersForDate = (orders) => {
  return createAction(
    RESTAURANTS_ACTION_TYPES.FETCH_RESTAURANTS_FOR_DATE,
    () => {
      return { payload: orders };
    }
  );
};

export const setSelectedSideMenuItem = (menuItem) => {
  return createAction(ORDERS_ACTION_TYPES.SET_SELECTED_SIDE_MENU_ITEM, () => {
    return { payload: menuItem };
  });
};

export const setSelectedExtraMenuItems = (menuItem) => {
  return createAction(ORDERS_ACTION_TYPES.SET_SELECTED_EXTRA_MENU_ITEM, () => {
    return { payload: menuItem };
  });
};

export const setSelectedMenuItem = (menuItem) => {
  return createAction(ORDERS_ACTION_TYPES.SET_SELECTED_MENU_ITEM, () => {
    return { payload: menuItem };
  });
};

export const setAllSelectedItems = (menuItem) => {
  return createAction(ORDERS_ACTION_TYPES.SET_ALL_SELECTED_ITEMS, () => {
    return { payload: menuItem };
  });
};

export const setSelectedOrderDate = (date) => {
  return createAction(ORDERS_ACTION_TYPES.SET_SELECTED_ORDER_DATE, () => {
    return { payload: date };
  });
};

export const setOrderComment = (comment) => {
  return createAction(ORDERS_ACTION_TYPES.SET_ORDER_COMMENT, () => {
    return { payload: comment };
  });
};

export const fetchOrdersEnd = () => {
  return createAction(ORDERS_ACTION_TYPES.FETCH_ORDERS_END);
};

export const clearCurrentOrder = () => {
  return createAction(ORDERS_ACTION_TYPES.CLEAR_CURRENT_ORDER);
};

export const setShouldSendEmail = (shouldSend) => {
  return createAction(ORDERS_ACTION_TYPES.SET_SHOULD_SEND_EMAIL, () => {
    return { payload: shouldSend };
  });
};

export const filterOrders = (orders, filters) => {
  const { dateFrom, dateTo, restaurant, user } = filters;
  if (dateFrom && !dateTo) {
    orders = orders.filter((order) => order.date >= dateFrom);
  }

  if (!dateFrom && dateTo) {
    orders = orders.filter((order) => order.date <= dateTo);
  }

  if (dateFrom && dateTo) {
    orders = orders.filter(
      (order) => order.date >= dateFrom && order.date <= dateTo
    );
  }
  if (restaurant) {
    orders = orders.filter((order) => order.restaurantName === restaurant);
  }

  if (user) {
    orders = orders.filter((order) => order.name === user);
  }

  return createAction(ORDERS_ACTION_TYPES.SET_FILTERED_ORDERS, () => {
    return { payload: orders };
  });
};
