export const REGEX_EMAIL = {
  DEFAULT: /^[A-Za-z0-9+_.-]+@(.+)$/,
  VALTECH: /(.+@valtech.)+(com$|mk$|de$)/,
};
export const REGEX_PASSWORD =
  /(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,30}$/;
export const REGEX_PHONENUMBER = /^\+(3897|3892)\d{7}$/;

export const REGEX_INPUT = {
  NAME: /^[a-zA-Z ]*$/,
  PHONENUMBER: /^\+(3897|3892)\d{7}$/,
  EMAIL: /^[A-Za-z0-9+_.-]+@(.+)+.(.+)$/,
  VALTECH_MAIL: /(.+@valtech.)+(com$|mk$|de$)/,
  TIME: /^\d+$/,
  NUMBER: /^[0-9]{1,10}$/,
};

export const REGEX_CATEGORY_INPUT = /^[\p{L}\p{M}\p{N}\s,/()-]+$/u;
