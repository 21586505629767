import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';

import { getCurrentUserTotalCost } from '../../../services/order.service';
import {
  currentUser,
  isAdmin,
} from '../../../store/currentUser/currentUser.selector';
import { currentUserOrders } from '../../../store/orders/orders.selector';
import { fetchCurrentUserOrdersPaginatedAsync } from '../../../store/orders/orders.thunk';
import { selectedUser } from '../../../store/users/users.selector';
import {
  deleteUserAsync,
  getUserAsync,
} from '../../../store/users/users.thunk';
import Button from '../../atoms/Button/Button';
import Image from '../../atoms/Image/Image';
import InputDate from '../../atoms/InputDate/InputDate';
import Pagination from '../../atoms/Pagination/Pagination';
import Modal from '../../molecules/Modal/Modal';
import Table from '../../molecules/Table/Table';

import './user.scss';

const User = () => {
  const visitedUser = useSelector(selectedUser);
  const { userId } = useParams();
  const admin = useSelector(isAdmin);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currUser = useSelector(currentUser).userDto;
  const currentUserOrdersData = useSelector(currentUserOrders);

  const [showModal, setShowModal] = useState(false);
  const [profileImage, setProfileImage] = useState('');
  const [tableData, setTableData] = useState([]);
  const [numberOfPage, setNumberOfPage] = useState(0);
  const [dateFrom, setDateFrom] = useState('');
  const [dateTo, setDateTo] = useState('');
  const [totalCost, setTotalCost] = useState(0);

  const userOrders = useMemo(
    () => currentUserOrdersData?.content || [],
    [currentUserOrdersData]
  );
  const totalOrders = currentUserOrdersData?.totalElements || 0;
  const personal = Number(userId) === currUser.id;

  useEffect(() => {
    if (admin) {
      dispatch(getUserAsync(userId));
    }
  }, [totalOrders, userId, admin, dispatch]);

  useEffect(() => {
    if (visitedUser?.imageDto) {
      fetch(`/api/image/data/${visitedUser.imageDto.id}`)
        .then((response) => response.blob())
        .then((blob) => setProfileImage(URL.createObjectURL(blob)))
        .catch((error) => console.error(error));
    }
  }, [visitedUser?.imageDto]);

  useEffect(() => {
    const fetchTotalCost = async () => {
      try {
        const cost = await getCurrentUserTotalCost(userId, dateFrom, dateTo);
        setTotalCost(cost);
      } catch (error) {
        console.error('Error fetching total cost:', error);
      }
    };

    fetchTotalCost();
  }, [userId, dateFrom, dateTo]);

  useEffect(() => {
    dispatch(fetchCurrentUserOrdersPaginatedAsync(0, userId, dateFrom, dateTo));
  }, [userId, dateFrom, dateTo, dispatch]);

  useEffect(() => {
    updateTableData(userOrders);
  }, [userOrders]);

  const updateTableData = (data) => {
    setTableData(
      data.map((order) => [
        order.date,
        order.userOrderId,
        order.restaurantName,
        order.extraItem && order.extraItem.length > 0
          ? `${order.mainItemAndSideItem} + ${order.extraItem.join(', ')}`
          : `${order.mainItemAndSideItem}`,
        order.comments,
        order.costForEmployees,
        order.orderStatus,
      ])
    );
  };

  const onPageChanged = (pageNum) => {
    setNumberOfPage(pageNum);
    dispatch(
      fetchCurrentUserOrdersPaginatedAsync(pageNum, userId, dateFrom, dateTo)
    );
  };

  const deleteUser = () => {
    dispatch(deleteUserAsync(userId, navigate));
    setShowModal(false);
  };

  return (
    <div className="row p-user">
      <div className="col__desktop-8 p-user__wrapper">
        <div className="row p-user__row">
          <div className="p-user__action-wrapper">
            <Image
              key={currUser.id}
              className="large p-user__action-wrapper__image"
              src={profileImage}
            />
            <div className="p-user__action-wrapper__button">
              <Link to={`/edit-profile/${userId}`}>
                <Button
                  text="edit profile"
                  classes="hover-bg--black slim large"
                />
              </Link>
              {admin && (
                <Button
                  onClick={() => setShowModal(true)}
                  text="remove user"
                  classes="bg--black hover-bg--red slim large"
                />
              )}
            </div>
          </div>
          <div className="p-user__info-wrapper">
            <h1 className="p-user__info-wrapper__heading">
              {personal ? currUser.firstName : visitedUser?.firstName}
            </h1>
            <p className="p-user__info-wrapper__blue-paragraph">
              {personal ? currUser.email : visitedUser?.email}
            </p>

            <div className="p-dashboard__table-sort row">
              <div className="p-dashboard__table-filters ">
                <div className="p-dashboard__table-sort__filters__input">
                  <InputDate
                    title="Date From"
                    name="date-from"
                    value={dateFrom}
                    onChange={(e) => setDateFrom(e.target.value)}
                  />
                </div>
                <div className="p-dashboard__table-sort__filters__input">
                  <InputDate
                    title="Date To"
                    name="date-to"
                    value={dateTo}
                    onChange={(e) => setDateTo(e.target.value)}
                  />
                </div>
              </div>
              <div className="p-dashboard__table-total-info">
                <p className="p-dashboard__total-orders">
                  Total Orders: <strong>{totalOrders}</strong>
                </p>
                <p className="p-dashboard__total-orders">
                  Total Cost: <strong>{totalCost}</strong>
                </p>
              </div>
            </div>

            <div className="p-dashboard__table-container p-dashboard__table-container-profile">
              <Table
                tableHeaders={[
                  'Date',
                  'Order ID',
                  'Restaurant',
                  'Meals Ordered',
                  'Comments',
                  'Cost',
                  'Status',
                  '',
                ]}
                tableData={tableData}
                isOrderTable={true}
              />
            </div>

            <Pagination
              currentPageParam={numberOfPage}
              numbersOfItems={totalOrders}
              onPageChange={onPageChanged}
            />
          </div>
        </div>
      </div>
      {showModal && (
        <div className="p-user__modal">
          <Modal
            showModal={showModal}
            setShowModal={setShowModal}
            hasBorder={true}
            hasBanner={true}
            title="The user will be removed. Are you sure ?"
          >
            <Button
              classes="bg--blue slim square small"
              text="Yes"
              onClick={deleteUser}
            />
            <Button
              classes="bg--blue slim square small"
              text="No"
              onClick={() => setShowModal(false)}
            />
          </Modal>
        </div>
      )}
    </div>
  );
};

export default User;
