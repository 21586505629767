import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams, Link } from 'react-router-dom';

import Modal from '../Modal/Modal';

import { isAdmin } from '../../../store/currentUser/currentUser.selector';
import { fetchSelectedMenuItem } from '../../../store/restaurants/restaurants.actions';
import {
  menuItems,
  selectedMenuId,
  selectedMenuItem,
} from '../../../store/restaurants/restaurants.selector';
import { deleteMenuItemAsync } from '../../../store/restaurants/restaurants.thunk';
import Button from '../../atoms/Button/Button';
import { IconBurger, IconEdit, IconX } from '../../atoms/Icons';

import './menuItem.scss';

const MenuItem = ({ items }) => {
  const admin = useSelector(isAdmin);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { restaurantId } = useParams();
  const menuId = useSelector(selectedMenuId) || restaurantId;
  const menuItemId = useSelector(selectedMenuItem)?.menuItemId;
  const [selectedIndex, setSelectedIndex] = useState(null);
  const allMenuItems = useSelector(menuItems);
  const [showModal, setShowModal] = useState(false);

  const editMenuItemHandler = (e) => {
    e.stopPropagation();
    navigate(`/edit-menu-item/${menuItemId}`);
  };

  const showModalHandler = (e) => {
    e.stopPropagation();
    setShowModal(true);
  };

  const removeMenuItem = () => {
    dispatch(
      deleteMenuItemAsync(restaurantId, menuId, menuItemId, allMenuItems)
    );
    setShowModal(false);
  };

  const handleMenuItemClick = (index, food) => {
    dispatch(fetchSelectedMenuItem(food)());
    selectedIndex === index ? setSelectedIndex(null) : setSelectedIndex(index);
  };

  return (
    <div className="row">
      {items.map((item, index) => (
        <div
          key={item.name}
          className="col__desktop-4 col__laptop-6 col__tablet-12 col__mobile-12"
          onClick={() => handleMenuItemClick(index, item)}
        >
          <article
            className={
              selectedIndex === index
                ? 'm-menu-item m-menu-item-active'
                : 'm-menu-item'
            }
          >
            <div className="m-menu-item__name">
              <h5>{item.name}</h5>
              <h5>{item.costForEmployees}</h5>
            </div>
            <div className="m-menu-item__ingredients">
              <p>{item.description}</p>
            </div>
            {admin && index === selectedIndex ? (
              <div className="m-menu-item__buttons">
                <button onClick={(e) => editMenuItemHandler(e)}>
                  <IconEdit />
                </button>
                <button onClick={(e) => showModalHandler(e)}>
                  <IconX />
                </button>
                {item.type === 'MAIN' && (
                  <Link
                    to={`/order?restaurantId=${restaurantId}&menuItemId=${item.menuItemId}`}
                  >
                    <button>
                      <IconBurger />
                    </button>
                  </Link>
                )}
              </div>
            ) : (
              <div className="m-menu-item__buttons">
                {item.type === 'MAIN' && index === selectedIndex && (
                  <Link
                    to={`/order?restaurantId=${restaurantId}&menuItemId=${item.menuItemId}`}
                  >
                    <button>
                      <IconBurger />
                    </button>
                  </Link>
                )}
              </div>
            )}
          </article>
          {showModal && (
            <div className="p-restaurant__modal">
              <Modal
                showModal={showModal}
                setShowModal={setShowModal}
                hasBorder={true}
                hasBanner={true}
                title="The menu item will be removed. Are you sure ?"
              >
                <Button
                  classes="bg--blue slim square small"
                  text="Yes"
                  onClick={removeMenuItem}
                />
                <Button
                  classes="bg--blue slim square small"
                  text="No"
                  onClick={() => setShowModal(false)}
                />
              </Modal>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default MenuItem;
