import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import {
  activeMenuList,
  filteredExtraItems,
  filteredSideItems,
} from '../MenuItemForm/filterFunctions';

import { MEALS_MISSING, TERMS } from '../../../constants/notification-messages';
import { handleNotificationAction } from '../../../store/notification/notification.action';
import {
  clearCurrentOrder,
  setSelectedMenuItem,
  setSelectedOrderDate,
} from '../../../store/orders/orders.actions';
import {
  selectedExtraMenuItems,
  selectedMenuItem,
  selectedOrderDate,
  selectedSideMenuItem,
  shouldSendEmail,
} from '../../../store/orders/orders.selector';
import { createOrderAsync } from '../../../store/orders/orders.thunk';
import {
  menuItems,
  restaurantsForDate,
  selectedRestaurant,
  termsAndConditionsStatus,
} from '../../../store/restaurants/restaurants.selector';
import {
  acceptTermsAndConditionsAsync,
  getCategoriesAsync,
  getMenuItemsAsync,
  getRestaurantAsync,
  getRestaurantsForDateAsync,
  getTermsAndConditionsStatusAsync,
} from '../../../store/restaurants/restaurants.thunk';
import { handleDateFormatting } from '../../../utils/orderHelperFunctions';
import Button from '../../atoms/Button/Button';
import { IconAlert } from '../../atoms/Icons';
import Form from '../../molecules/Form/Form';
import Modal from '../../molecules/Modal/Modal';

import FinalStep from './FinalStep';
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import Step4 from './Step4';
import Step5 from './Step5';
import Step6 from './Step6';
import './order.scss';

// Custom hook
const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const Order = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const allItems = activeMenuList(useSelector(menuItems));
  const orderDate = useSelector(selectedOrderDate);
  const restaurant = useSelector(selectedRestaurant);
  let orderMainMenuItem = useSelector(selectedMenuItem);
  const orderSideMenuItem = useSelector(selectedSideMenuItem);
  const orderExtraMenuItems = useSelector(selectedExtraMenuItems);
  let termsAndConditionsAccepted = useSelector(termsAndConditionsStatus);
  const sendEmail = useSelector(shouldSendEmail);
  const allRestaurants = useSelector(restaurantsForDate);
  let tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  const [currentDate, setCurrentDate] = useState(
    orderDate ? orderDate : tomorrow
  );
  const [showTermsModal, setShowTermsModal] = useState(false);
  const [step, setStep] = useState(1);

  const queryParameters = useQuery();
  const selectedRestaurantId = queryParameters.get('restaurantId');
  const selectedMenuItemId = queryParameters.get('menuItemId');

  if (selectedMenuItemId) {
    orderMainMenuItem = allItems.find(
      (item) => item.menuItemId === Number(selectedMenuItemId)
    );
    dispatch(setSelectedMenuItem(orderMainMenuItem)());
  }

  const hasSideItems = orderMainMenuItem
    ? filteredSideItems(allItems, [orderMainMenuItem])?.length > 0
    : false;
  const hasExtraItems = orderMainMenuItem
    ? filteredExtraItems(allItems, [orderMainMenuItem])?.length > 0
    : false;

  const [terms, setTerms] = useState(restaurant?.termsAndConditions);

  const acceptTermsAndConditions = () => {
    dispatch(acceptTermsAndConditionsAsync(restaurant.id));
    termsAndConditionsAccepted = true;
    setShowTermsModal(false);
    nextHandler();
  };

  useEffect(() => {
    return () => {
      dispatch(clearCurrentOrder()());
    };
  }, [dispatch]); // eslint-disable-line

  useEffect(() => {
    if (allRestaurants.length) {
      const firstRestaurantId = selectedRestaurantId
        ? selectedRestaurantId
        : allRestaurants[0]?.id;
      dispatch(getRestaurantAsync(firstRestaurantId));
      dispatch(getTermsAndConditionsStatusAsync(firstRestaurantId));
    }
  }, [allRestaurants]); // eslint-disable-line

  useEffect(() => {
    if (step === 3) {
      dispatch(getCategoriesAsync());
    }
  }, [step]); // eslint-disable-line

  useEffect(() => {
    const date = currentDate.toString().slice(0, 15);
    dispatch(getRestaurantsForDateAsync(date));
    dispatch(setSelectedOrderDate(date)());
  }, [currentDate]); // eslint-disable-line

  useEffect(() => {
    if (restaurant) {
      dispatch(getMenuItemsAsync(restaurant.id, restaurant.menuId));
    }
  }, [restaurant]); // eslint-disable-line
  let content;

  const nextHandler = () => {
    if (step === 1 && selectedMenuItemId) {
      if (!termsAndConditionsAccepted) {
        setShowTermsModal(true);
        return;
      }
      let stepsToSkip;
      if (hasSideItems) {
        stepsToSkip = 3;
      } else if (hasExtraItems) {
        stepsToSkip = 4;
      } else {
        stepsToSkip = 5;
      }
      setStep(step + stepsToSkip);
      return;
    } else if (step === 2 && !termsAndConditionsAccepted) {
      setShowTermsModal(true);
      dispatch(handleNotificationAction({ title: TERMS, status: 'error' })());
      return;
    } else if (step === 3 && !orderMainMenuItem) {
      dispatch(
        handleNotificationAction({
          title: MEALS_MISSING.MAIN,
          status: 'error',
        })()
      );
      return;
    } else if (step === 3) {
      let stepToSkip = 1;
      if (!hasSideItems) {
        stepToSkip = !hasExtraItems ? 3 : 2;
      }
      setStep(step + stepToSkip);
      return;
    } else if (step === 4 && !hasExtraItems) {
      setStep(step + 2);
      return;
    }
    setStep(step + 1);
  };

  const backHandler = () => {
    if (selectedMenuItemId) {
      if (step === 7) {
        setStep(6);
        return;
      } else if (step === 6) {
        if (hasExtraItems) {
          setStep(5);
          return;
        } else if (hasSideItems) {
          setStep(4);
          return;
        }
      } else if (step === 5 && hasSideItems) {
        setStep(4);
        return;
      }
      setStep(1);
      return;
    } else if (step === 6) {
      let stepToSkip = 1;
      if (!hasExtraItems) {
        stepToSkip = !hasSideItems ? 3 : 2;
      }
      setStep(step - stepToSkip);
      return;
    } else if (step === 5 && !hasSideItems) {
      setStep(step - 2);
      return;
    }
    setStep(step - 1);
  };

  switch (step) {
    case 1:
      content = (
        <Step1 currentDate={currentDate} setCurrentDate={setCurrentDate} />
      );
      break;
    case 2:
      content = (
        <Step2
          allRestaurants={allRestaurants}
          showTermsModal={showTermsModal}
          setShowTermsModal={setShowTermsModal}
          selectedRestaurantId={selectedRestaurantId}
        />
      );
      break;
    case 3:
      content = <Step3 allItems={allItems} orderDate={orderDate} />;
      break;
    case 4:
      content = (
        <Step4 allItems={allItems} orderMainMenuItem={orderMainMenuItem} />
      );
      break;
    case 5:
      content = (
        <Step5 allItems={allItems} orderMainMenuItem={orderMainMenuItem} />
      );
      break;
    case 6:
      content = <Step6 />;
      break;
    case 7:
      content = <FinalStep />;
      break;
    default:
      content = null;
      break;
  }

  const placeOrder = (data) => {
    let comment = data.comment;
    if (restaurant.id === 7 && data.deliveryLocation) {
      if (data.deliveryLocation === 'office') {
        comment = 'Ke jadam vo kancelarijata. ' + comment;
      } else if (data.deliveryLocation === 'restaurant') {
        comment = 'Ke jadam vo restoranot. ' + comment;
      }
    }
    const orderData = {
      restaurantId: restaurant.id,
      locationId: Number(data.locationId) || 1, // temp hacky fix until location is not implemented
      menuId: restaurant.menuId,
      shouldSendEmail: sendEmail,
      comment: comment,
      mainMenuItemId: orderMainMenuItem.menuItemId,
      sideMenuItemId: orderSideMenuItem?.menuItemId,
      extraMenuItemIdList: orderExtraMenuItems.map((item) => item.menuItemId),
      date: handleDateFormatting(currentDate.toLocaleDateString('en-GB')),
    };

    dispatch(createOrderAsync(orderData, navigate));
  };

  return (
    <>
      <div className="p-order">
        <Form id="order" className="p-order__form" onSubmit={placeOrder}>
          {content}
        </Form>
        {!allRestaurants.length && (
          <div className="p-order__alert">
            <IconAlert />
            <p>
              There are no available restaurants for this date. Please choose
              another date.
            </p>
          </div>
        )}
        <div className="p-order__button-group">
          <Button
            classes={`square ${step === 1 ? 'hidden' : ''}`}
            text="back"
            onClick={backHandler}
          />
          {step !== 7 && (
            <Button
              disabled={!allRestaurants.length}
              classes="square"
              text="next"
              onClick={nextHandler}
            />
          )}
          {step === 7 && (
            <Button classes="square" text="order" type="submit" form="order" />
          )}
        </div>
      </div>
      {showTermsModal && (
        <Modal
          showModal={showTermsModal}
          setShowModal={setShowTermsModal}
          hasBorder
          hasBanner
          title="Terms & Conditions"
          allowClose={false}
        >
          <div
            className="terms__content"
            dangerouslySetInnerHTML={{ __html: terms }}
          ></div>
          <div className="terms__buttons">
            <Button text="decline" onClick={() => window.location.reload()} />
            <Button text="accept" onClick={acceptTermsAndConditions} />
          </div>
        </Modal>
      )}
    </>
  );
};

export default Order;
