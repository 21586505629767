//fetchUserAsync is the Thunk action that will trigger the three states from the above,
// it is also best practice to call this actions Async so everybody will know that these functions are thunk functions
import {
  handleNotificationAction,
  handleNotificationError,
} from '../notification/notification.action';

import {
  ADD_IMAGE,
  DECLINED_REQUEST,
  DELETE_IMAGE,
  MODIFY_IMAGE,
  MODIFY_USER,
  NEW_ACCOUNT_REQUEST,
  SESSION,
  UPDATE_IMAGE,
  UPDATE_PROFILE,
} from '../../constants/notification-messages';
import AuthService from '../../services/auth.service';
import UserService from '../../services/user.service';

import {
  actionEnd,
  actionStart,
  loginUserFailed,
  loginUserStart,
  logoutUserSuccess,
  removeUser,
  storeUserDetails,
  successfullyRegistered,
} from './currentUser.action';

export const loginUserAsync = (username, password) => async (dispatch) => {
  dispatch(loginUserStart()());
  try {
    const response = await AuthService.login(username, password);
    const { userDto, role } = response;
    dispatch(storeUserDetails({ userDto, role })());
  } catch (error) {
    const statusCode = error.response.status;
    if (statusCode === 504) {
      dispatch(loginUserFailed()());
      dispatch(
        handleNotificationAction({
          title: DECLINED_REQUEST.LOGIN_TITLE,
          message: DECLINED_REQUEST.LOGIN_MESSAGE,
          status: 'error',
        })()
      );
    } else {
      dispatch(loginUserFailed('Something is wrong')());
    }
  }
};

export const logoutUserAsync = () => async (dispatch) => {
  dispatch(actionStart()());
  try {
    const response = await AuthService.logout();
    dispatch(logoutUserSuccess(response)());
  } catch (error) {
    dispatch(
      handleNotificationError(error, {
        title: DECLINED_REQUEST.LOGOUT_TITLE,
        message: DECLINED_REQUEST.LOGOUT_MESSAGE,
      })
    );
  } finally {
    dispatch(actionEnd()());
  }
};

export const editProfileAsync =
  (userId, firstName, lastName, email, cardId, userRole, company) =>
  async (dispatch) => {
    dispatch(actionStart()());
    try {
      await UserService.editProfile(
        userId,
        firstName,
        lastName,
        email,
        cardId,
        userRole,
        company
      );
      dispatch(
        handleNotificationAction({
          title: MODIFY_USER.SUCCESS,
        })()
      );
    } catch (error) {
      const errorMessage = error.response.data.message;
      dispatch(
        handleNotificationError(error, {
          title: errorMessage || MODIFY_USER.ERROR,
        })
      );
    } finally {
      dispatch(actionEnd()());
    }
  };

export const updateProfileAsync =
  (firstName, lastName, email, cardId) => async (dispatch) => {
    dispatch(actionStart()());
    try {
      const response = await UserService.updateProfile(
        firstName,
        lastName,
        email,
        cardId
      );
      const { userDto, role } = response;
      dispatch(storeUserDetails({ userDto, role })());
      dispatch(
        handleNotificationAction({
          title: UPDATE_PROFILE.SUCCESS,
        })()
      );
    } catch (error) {
      const errorMessage = error.response.data.message;

      dispatch(
        handleNotificationError(error, {
          title: errorMessage || UPDATE_PROFILE.ERROR,
        })
      );
    } finally {
      dispatch(actionEnd()());
    }
  };

export const registerAsync =
  (firstName, lastName, email, cardId, company) => async (dispatch) => {
    dispatch(actionStart()());
    try {
      await AuthService.register(firstName, lastName, email, cardId, company);
      dispatch(
        handleNotificationAction({ title: NEW_ACCOUNT_REQUEST.SUCCESS })()
      );
      dispatch(successfullyRegistered()());
    } catch (error) {
      const errorMessage = error.response.data.message;

      dispatch(
        handleNotificationError(error, {
          title: errorMessage || NEW_ACCOUNT_REQUEST.ERROR,
        })
      );
    } finally {
      dispatch(actionEnd()());
    }
  };

export const refreshTokenAsync = () => async (dispatch) => {
  dispatch(actionStart()());
  try {
    await AuthService.refreshToken();
  } catch (error) {
    dispatch(
      handleNotificationError(error, {
        title: SESSION.CANNOT_CONTINUE,
      })
    );

    localStorage.removeItem('access_token');
    dispatch(removeUser()());
  } finally {
    dispatch(actionEnd()());
  }
};

export const confirmEmailAsync = (token) => async (dispatch) => {
  dispatch(actionStart()());
  try {
    await AuthService.confirmEmail(token);
  } catch (error) {
    dispatch(
      handleNotificationError(error, {
        title: DECLINED_REQUEST.CONFIRM_EMAIL,
      })
    );
  } finally {
    dispatch(actionEnd()());
  }
};

export const addProfileImageAsync =
  (userId, currentUserId, image, displayNotification = false) =>
  async (dispatch) => {
    dispatch(actionStart()());
    try {
      await UserService.addImage(userId, image);
      displayNotification &&
        dispatch(
          handleNotificationAction({
            title: ADD_IMAGE.SUCCESS,
          })()
        );
    } catch (error) {
      const statusCode = error.response.status;
      if (statusCode !== 401) {
        displayNotification &&
          dispatch(
            handleNotificationAction({
              title: ADD_IMAGE.ERROR,
              status: 'error',
            })()
          );
      }
    } finally {
      dispatch(actionEnd()());
    }
  };

export const editProfileImageAsync =
  (userId, currentUserId, imageId, image, displayNotification = false) =>
  async (dispatch) => {
    dispatch(actionStart()());
    try {
      await UserService.editImage(userId, imageId, image);
      displayNotification &&
        dispatch(
          handleNotificationAction({
            title:
              Number(userId) === currentUserId
                ? UPDATE_IMAGE.SUCCESS
                : MODIFY_IMAGE.SUCCESS,
          })()
        );
    } catch (error) {
      if (displayNotification) {
        dispatch(
          handleNotificationError(error, {
            title:
              Number(userId) === currentUserId
                ? UPDATE_IMAGE.ERROR
                : MODIFY_IMAGE.ERROR,
          })
        );
      }
    } finally {
      dispatch(actionEnd()());
    }
  };

export const deleteProfileImageAsync =
  (imageId, displayNotification = false) =>
  async (dispatch) => {
    dispatch(actionStart()());
    try {
      await UserService.deleteImage(imageId);
      displayNotification &&
        dispatch(
          handleNotificationAction({
            title: DELETE_IMAGE.SUCCESS,
          })()
        );
    } catch (error) {
      const statusCode = error.response.status;
      if (statusCode !== 401) {
        displayNotification &&
          dispatch(
            handleNotificationAction({
              title: DELETE_IMAGE.ERROR,
              status: 'error',
            })()
          );
      }
    } finally {
      dispatch(actionEnd()());
    }
  };
