import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  setSelectedExtraMenuItems,
  setSelectedSideMenuItem,
  setSelectedMenuItem,
} from '../../../store/orders/orders.actions';
import {
  selectedMenuItem,
  selectedSideMenuItem,
  selectedExtraMenuItems,
} from '../../../store/orders/orders.selector';
import { handleExtraItemSelection } from '../../../utils/orderHelperFunctions';

import './orderItem.scss';

const OrderItem = ({ items, type }) => {
  const dispatch = useDispatch();
  const menuItem = useSelector(selectedMenuItem);
  const sideMenuItem = useSelector(selectedSideMenuItem);
  const extraMenuItems = useSelector(selectedExtraMenuItems);

  const handleOrderMenuItemClick = (item, type) => {
    let newItems;

    switch (type) {
      case 'side':
        sideMenuItem?.menuItemId === item.menuItemId
          ? dispatch(setSelectedSideMenuItem(null)())
          : dispatch(setSelectedSideMenuItem(item)());
        break;
      case 'extra':
        newItems = handleExtraItemSelection(extraMenuItems, item);
        dispatch(setSelectedExtraMenuItems(newItems)());
        break;
      default:
        if (menuItem?.menuItemId === item.menuItemId) {
          dispatch(setSelectedMenuItem(null)());
          dispatch(setSelectedSideMenuItem(null)());
          dispatch(setSelectedExtraMenuItems([])());
        } else {
          dispatch(setSelectedMenuItem(item)());
          dispatch(setSelectedSideMenuItem(null)());
          dispatch(setSelectedExtraMenuItems([])());
        }
    }
  };

  return (
    <div className="row">
      {items.map((item) => (
        <div
          key={item.name}
          className="col__desktop-4 col__laptop-6 col__tablet-12 col__mobile-12"
          onClick={() => handleOrderMenuItemClick(item, type)}
        >
          <article
            className={
              menuItem?.menuItemId === item.menuItemId ||
              sideMenuItem?.menuItemId === item.menuItemId ||
              extraMenuItems?.includes(item)
                ? 'm-selected-order-item'
                : 'm-order-item'
            }
          >
            <div className="m-order-item__name">
              <h5>{item.name}</h5>
              <h5>{item.costForEmployees}</h5>
            </div>
            <div className="m-order-item__ingredients">
              <p>{item.description}</p>
            </div>
          </article>
        </div>
      ))}
    </div>
  );
};

export default OrderItem;
