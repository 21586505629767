import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { DECLINED_REQUEST } from '../../../constants/notification-messages';
import { handleNotificationAction } from '../../../store/notification/notification.action';
import { filterOrders } from '../../../store/orders/orders.actions';
import {
  orders,
  filteredOrders,
  ordersTotal,
} from '../../../store/orders/orders.selector';
import { fetchOrdersPaginatedAsync } from '../../../store/orders/orders.thunk';
import { restaurants } from '../../../store/restaurants/restaurants.selector';
import { fetchRestaurantsAsync } from '../../../store/restaurants/restaurants.thunk';
import { fetchAllUsers } from '../../../store/users/users.selector';
import { allUsersAsync } from '../../../store/users/users.thunk';
import {
  validDateTo,
  validDateFrom,
} from '../../../utils/orderHistoryHelperFunctions';
import InputDate from '../../atoms/InputDate/InputDate';
import Pagination from '../../atoms/Pagination/Pagination';
import Table from '../../molecules/Table/Table';

import './orderHistory.scss';

const OrderHistory = () => {
  const dispatch = useDispatch();
  const allOrders = useSelector(orders);
  const totalOrders = useSelector(ordersTotal);
  const allOrdersFiltered = useSelector(filteredOrders);
  const allRestaurants = useSelector(restaurants);
  const allUsers = useSelector(fetchAllUsers);
  const [tableData, setTableData] = useState([]);
  const [dateFrom, setDateFrom] = useState();
  const [dateTo, setDateTo] = useState();
  const [restaurant, setRestaurant] = useState();
  const [user, setUser] = useState('');
  const [company, setCompany] = useState();
  const [status, setStatus] = useState();
  const [numberOfPage, setNumberOfPage] = useState(0);

  useEffect(() => {
    setNumberOfPage(0);
    dispatch(
      fetchOrdersPaginatedAsync(
        0,
        dateFrom,
        dateTo,
        restaurant,
        user,
        company,
        status
      )
    );
  }, [dateFrom, dateTo, restaurant, user, company, status]); // eslint-disable-line

  const onChangeTo = (event) => {
    let selectedDate = event.target.value;
    validDateTo(dateFrom, selectedDate)
      ? dispatch(
          handleNotificationAction({
            title: DECLINED_REQUEST.ORDER_DATE,
            message: `Date cannot be before ${dateFrom}`,
            status: 'error',
          })()
        )
      : setDateTo(selectedDate);
  };

  const onChangeFrom = (event) => {
    let selectedDate = event.target.value;
    if (validDateFrom(dateTo, selectedDate)) {
      setDateFrom(selectedDate);
      setDateTo(selectedDate);
    } else {
      setDateFrom(selectedDate);
    }
  };

  const onPageChanged = (pageNum) => {
    setNumberOfPage(pageNum);
    dispatch(
      fetchOrdersPaginatedAsync(
        pageNum,
        dateFrom,
        dateTo,
        restaurant,
        user,
        company,
        status
      )
    );
  };

  useEffect(() => {
    if (allRestaurants.length === 0) {
      dispatch(fetchRestaurantsAsync());
    }

    if (allUsers.length === 0) {
      dispatch(allUsersAsync());
    }
  }, []); //eslint-disable-line

  useEffect(() => {
    if (allOrdersFiltered) {
      setTableData(
        allOrdersFiltered.map((order) => [
          order.date,
          order.userOrderId,
          order.name,
          order.restaurantName,
          order.extraItem && order.extraItem.length > 0
            ? `${order.mainItemAndSideItem} + ${order.extraItem.join(', ')}`
            : `${order.mainItemAndSideItem}`,
          order.comments,
          order.orderStatus !== 'CANCELLED' ? order.costForEmployees : 0,
          order.orderStatus,
        ])
      );
      return;
    }
    setTableData(
      allOrders.map((order) => [
        order.date,
        order.userOrderId,
        order.name,
        order.restaurantName,
        order.extraItem && order.extraItem.length > 0
          ? `${order.mainItemAndSideItem} + ${order.extraItem.join(', ')}`
          : `${order.mainItemAndSideItem}`,
        order.comments,
        order.costForEmployees,
        order.orderStatus,
      ])
    );
  }, [allOrders, allOrdersFiltered]);

  return (
    <div className="order-history-container">
      <h1>Order History</h1>
      <div className="order-history-container__filters row">
        <div className="order-history-container__filters__input">
          <InputDate
            title="Date From"
            name="date-from"
            value={dateFrom}
            onChange={onChangeFrom}
          />
        </div>
        <div className="order-history-container__filters__input">
          <InputDate
            title="Date To"
            name="date-to"
            value={dateTo}
            onChange={onChangeTo}
          />
        </div>
        <div className="order-history-container__filters__select">
          <div className="order-history-container__filters__select__input">
            <label data-testid="select-label-element">Restaurant</label>
            <select
              name="restaurant"
              onChange={(e) => setRestaurant(e.target.value)}
              value={restaurant}
              defaultValue={''}
            >
              <option value={''}>Restaurant..</option>
              {allRestaurants.map((restaurant) => (
                <option key={restaurant.id} value={restaurant.id}>
                  {restaurant.name}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="order-history-container__filters__select">
          <div className="order-history-container__filters__select__input">
            <label data-testid="select-label-element">User</label>
            <select
              name="user"
              onChange={(e) => setUser(e.target.value)}
              value={user}
              defaultValue={''}
            >
              <option value={''}>Users..</option>
              {allUsers.map((user) => (
                <option key={user.id} value={user.id}>
                  {user.firstName} {user.lastName}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="order-history-container__filters__select">
          <div className="order-history-container__filters__select__input">
            <label data-testid="select-label-element">Company</label>
            <select
              name="company"
              onChange={(e) => setCompany(e.target.value)}
              value={company}
            >
              <option value={''} selected>
                Company..
              </option>
              <option key="KinCarta" value="KinCarta">
                KinCarta
              </option>
              <option key="Valtech" value="Valtech">
                Valtech
              </option>
            </select>
          </div>
        </div>
        <div className="order-history-container__filters__select">
          <div className="order-history-container__filters__select__input">
            <label data-testid="select-label-element">Status</label>
            <select
              name="status"
              onChange={(e) => setStatus(e.target.value)}
              value={status}
            >
              <option value={''} selected>
                Status..
              </option>
              <option key="PENDING" value="PENDING">
                PENDING
              </option>
              <option key="IN_PROGRESS" value="IN_PROGRESS">
                IN_PROGRESS
              </option>
              <option key="COMPLETED" value="COMPLETED">
                COMPLETED
              </option>
              <option key="CANCELLED" value="CANCELLED">
                CANCELLED
              </option>
            </select>
          </div>
        </div>
      </div>
      <div className="order-history-container__table">
        <Table
          tableHeaders={[
            'Date',
            'Order ID',
            'User',
            'Restaurant',
            'Meals Ordered',
            'Comments',
            'Cost',
            'Status',
            'Action',
          ]}
          tableData={tableData}
          isOrderTable={true}
        />
      </div>
      <Pagination
        currentPageParam={numberOfPage}
        numbersOfItems={totalOrders}
        onPageChange={onPageChanged}
      />
    </div>
  );
};

export default OrderHistory;
