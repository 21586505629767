import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { currentUser } from '../../../store/currentUser/currentUser.selector';
import {
  allUsersNumber,
  fetchAllUsers,
} from '../../../store/users/users.selector';
import { allUsersPaginatedAsync } from '../../../store/users/users.thunk';
import Button from '../../atoms/Button/Button';
import Pagination from '../../atoms/Pagination/Pagination';
import UserList from '../../molecules/UserList/UserList';
import UserSearch from '../../molecules/UserSearch/UserSearch';

import './AllUsers.scss';

const AllUsers = () => {
  const [numberOfPage, setNumberOfPage] = useState(0);
  const [company, setCompany] = useState();
  const [searchText, setSearchText] = useState();
  const dispatch = useDispatch();
  const user = useSelector(currentUser);
  const users = useSelector(fetchAllUsers);
  const numberOfUsers = useSelector(allUsersNumber);
  let isAdmin = user.role === 'SUPER_ADMIN' || user.role === 'ADMIN';

  useEffect(() => {
    if (!searchText || searchText.length === 0 || searchText.length > 2) {
      setNumberOfPage(0);
      dispatch(allUsersPaginatedAsync(0, searchText, company));
    }
  }, [searchText]);
  useEffect(() => {
    setNumberOfPage(0);
    dispatch(allUsersPaginatedAsync(0, searchText, company));
  }, [company]); // eslint-disable-line

  const onPageChanged = (pageNum) => {
    setNumberOfPage(pageNum);
    dispatch(allUsersPaginatedAsync(pageNum, searchText, company));
  };
  return (
    <>
      <div className="p-all-users row">
        <h1 className="col__desktop-6 col__tablet-6 col__mobile-12 content-start">
          All Users
        </h1>
        {isAdmin && (
          <>
            <Link
              to="/create-new-user"
              className="col__desktop-6 col__tablet-6 col__mobile-12 content-end"
            >
              <Button
                text="add user"
                hoverText="+"
                classes="bg--blue hover-bg--black large slim"
              />
            </Link>
            <Link
              to="/account-requests"
              className="col__desktop-6 col__tablet-6 col__mobile-12 content-end"
            >
              <Button
                text="manage requests"
                hoverText="+"
                classes="bg--blue hover-bg--black large slim"
              />
            </Link>
          </>
        )}
      </div>
      <>
        <UserSearch
          searchText={searchText}
          setSearchText={setSearchText}
          company={company}
          setCompany={setCompany}
        />
        <UserList users={users} />
        <Pagination
          currentPageParam={numberOfPage}
          numbersOfItems={numberOfUsers}
          onPageChange={onPageChanged}
        />
      </>
    </>
  );
};

export default AllUsers;
