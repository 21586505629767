import { useFormContext } from 'react-hook-form';

import './selectInput.scss';

const SelectInput = ({
  name,
  label,
  options,
  onChange = () => {},
  value,
  defaultValue,
  classNames = 'a-select-input',
}) => {
  const { register } = useFormContext();

  return (
    <div className={classNames}>
      <label data-testid="select-label-element">{label}</label>
      <select
        {...register(name)}
        onChange={(e) => onChange(e.target.value)}
        defaultValue={defaultValue}
      >
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
};

export default SelectInput;
