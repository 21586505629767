import {
  handleNotificationAction,
  handleNotificationError,
} from '../notification/notification.action';

import {
  ACTIVATE_RESTAURANT,
  CREATE_CATEGORY,
  REMOVE_CATEGORY,
  EDIT_CATEGORY,
  CREATE_MENU_ITEM,
  CREATE_RESTAURANT,
  DEACTIVATE_RESTAURANT,
  DECLINED_REQUEST,
  DELETE_MENU_ITEM,
  EDIT_MENU_ITEM,
  EDIT_RESTAURANT,
  REMOVE_RESTAURANT,
} from '../../constants/notification-messages';
import RestaurantService from '../../services/restaurant.service';
import restaurantService from '../../services/restaurant.service';

import {
  fetchCategoriesSuccess,
  fetchLimitForOrders,
  fetchLocationsSuccess,
  fetchMenuItemsSuccess,
  fetchMenusSuccess,
  fetchRestaurantsEnd,
  fetchRestaurantsForDate,
  fetchRestaurantsStart,
  fetchRestaurantsSuccess,
  fetchSelectedRestaurantSuccess,
  fetchUpdatedMenuItems,
  fetchUpdatedCategories,
  successfullyCreatedMenuItem,
  successfullyCreatedCategory,
  fetchTermsAndConditionsStatusStart,
  fetchTermsAndConditionsStatusSuccess,
  fetchTermsAndConditionsStatusEnd,
  acceptTermsAndConditionsStart,
  acceptTermsAndConditionsSuccess,
  acceptTermsAndConditionsEnd,
} from './restaurants.actions';

export const fetchRestaurantsAsync = () => async (dispatch) => {
  dispatch(fetchRestaurantsStart()());

  try {
    const response = await RestaurantService.getAllRestaurants();
    if (response.status === 200) {
      dispatch(fetchRestaurantsSuccess(response.data.content || [])());
    } else {
      dispatch(
        handleNotificationAction({
          title: DECLINED_REQUEST.ERROR_MESSAGE,
          status: 'error',
        })()
      );
    }
  } catch (error) {
    dispatch(
      handleNotificationError(error, {
        title: DECLINED_REQUEST.ALL_RESTAURANTS,
      })
    );
  } finally {
    dispatch(fetchRestaurantsEnd()());
  }
};

export const restaurantStatusAsync =
  (id, allRestaurants) => async (dispatch) => {
    dispatch(fetchRestaurantsStart()());
    const selectedRestaurant = allRestaurants.find(
      (restaurant) => restaurant.id === id
    );
    const restaurantStatus = selectedRestaurant.status;
    const restaurantStatusUpdated =
      restaurantStatus === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE';
    try {
      await restaurantService.handleRestaurantStatus(
        id,
        restaurantStatusUpdated === 'ACTIVE' ? 'reactivate' : 'deactivate'
      );
      dispatch(
        restaurantStatus === 'ACTIVE'
          ? handleNotificationAction({
              title: DEACTIVATE_RESTAURANT.SUCCESS,
              status: 'error',
            })()
          : handleNotificationAction({ title: ACTIVATE_RESTAURANT.SUCCESS })()
      );
      const updatedRestaurants = allRestaurants.map((restaurant) => {
        if (restaurant.id === id) {
          return {
            ...restaurant,
            status: restaurantStatusUpdated,
          };
        }
        return restaurant;
      });
      dispatch(fetchRestaurantsSuccess(updatedRestaurants)());
    } catch (error) {
      dispatch(
        handleNotificationError(error, {
          title:
            restaurantStatus === 'ACTIVE'
              ? DEACTIVATE_RESTAURANT.ERROR
              : ACTIVATE_RESTAURANT.ERROR,
        })
      );
    } finally {
      dispatch(fetchRestaurantsEnd()());
    }
  };

export const createRestaurantAsync =
  (data, restaurantLocations, navigate) => async (dispatch) => {
    dispatch(fetchRestaurantsStart()());
    const {
      name,
      email,
      phonenumber,
      termsAndConditions,
      costForEmployees,
      maxOrdersPerDay,
      dailyCancelationTime,
      dailyOrderTime,
      orderSendingTime,
      requestedDeliveryTime,
      workingDays,
    } = data;
    try {
      await RestaurantService.createRestaurant(
        name,
        email,
        phonenumber,
        termsAndConditions,
        parseFloat(costForEmployees),
        Number(maxOrdersPerDay),
        Number(dailyCancelationTime),
        Number(dailyOrderTime),
        Number(orderSendingTime),
        Number(requestedDeliveryTime),
        workingDays,
        restaurantLocations
      );
      dispatch(
        handleNotificationAction({ title: CREATE_RESTAURANT.SUCCESS })()
      );
      navigate('/restaurant');
    } catch (error) {
      const errorMessage = error.response.data.message;

      dispatch(
        handleNotificationError(error, {
          title: errorMessage || CREATE_RESTAURANT.ERROR,
        })
      );
    } finally {
      dispatch(fetchRestaurantsEnd()());
    }
  };

export const editRestaurantAsync =
  (id, data, restaurantLocations, navigate) => async (dispatch) => {
    dispatch(fetchRestaurantsStart()());
    const {
      name,
      email,
      phonenumber,
      termsAndConditions,
      costForEmployees,
      maxOrdersPerDay,
      dailyCancelationTime,
      dailyOrderTime,
      orderSendingTime,
      requestedDeliveryTime,
      workingDays,
    } = data;
    try {
      await RestaurantService.editRestaurant(
        id,
        name,
        email,
        phonenumber,
        termsAndConditions,
        parseFloat(costForEmployees),
        Number(maxOrdersPerDay),
        Number(dailyCancelationTime),
        Number(dailyOrderTime),
        Number(orderSendingTime),
        Number(requestedDeliveryTime),
        workingDays,
        restaurantLocations
      );
      dispatch(handleNotificationAction({ title: EDIT_RESTAURANT.SUCCESS })());
      navigate(`/restaurant/${id}`);
    } catch (error) {
      const errorMessage = error.response.data.message;

      dispatch(
        handleNotificationError(error, {
          title: errorMessage || EDIT_RESTAURANT.ERROR,
        })
      );
    } finally {
      dispatch(fetchRestaurantsEnd()());
    }
  };

export const getRestaurantAsync = (restaurantId) => async (dispatch) => {
  dispatch(fetchRestaurantsStart()());
  try {
    const response = await restaurantService.getRestaurantById(restaurantId);
    dispatch(fetchSelectedRestaurantSuccess(response.data || [])());
  } catch (error) {
    dispatch(
      handleNotificationError(error, {
        title: DECLINED_REQUEST.RESTAURANT,
      })
    );
  } finally {
    dispatch(fetchRestaurantsEnd()());
  }
};

export const getTermsAndConditionsStatusAsync =
  (restaurantId) => async (dispatch) => {
    dispatch(fetchTermsAndConditionsStatusStart()());
    try {
      const response = await restaurantService.getTermsAndConditionsStatus(
        restaurantId
      );
      dispatch(fetchTermsAndConditionsStatusSuccess(response.data || false)());
    } catch (error) {
      dispatch(
        handleNotificationError(error, {
          title: DECLINED_REQUEST.TERMS_AND_CONDITIONS_STATUS,
        })
      );
    } finally {
      dispatch(fetchTermsAndConditionsStatusEnd()());
    }
  };

export const acceptTermsAndConditionsAsync =
  (restaurantId) => async (dispatch) => {
    dispatch(acceptTermsAndConditionsStart()());
    try {
      const response = await restaurantService.acceptTermsAndConditions(
        restaurantId
      );
      dispatch(acceptTermsAndConditionsSuccess(response.data || true)());
    } catch (error) {
      dispatch(
        handleNotificationError(error, {
          title: DECLINED_REQUEST.ACCEPT_TERMS_AND_CONDITIONS,
        })
      );
    } finally {
      dispatch(acceptTermsAndConditionsEnd()());
    }
  };

export const removeRestaurantAsync =
  (restaurantId, navigate) => async (dispatch) => {
    dispatch(fetchRestaurantsStart()());
    try {
      await restaurantService.removeRestaurant(restaurantId);
      dispatch(
        handleNotificationAction({
          title: REMOVE_RESTAURANT.SUCCESS,
          status: 'error',
        })()
      );
      navigate('/restaurant');
    } catch (error) {
      dispatch(
        handleNotificationError(error, {
          title: REMOVE_RESTAURANT.ERROR,
        })
      );
    } finally {
      dispatch(fetchRestaurantsEnd()());
    }
  };

export const getCategoriesAsync = () => async (dispatch) => {
  dispatch(fetchRestaurantsStart()());
  try {
    const response = await restaurantService.getAllCategories();
    dispatch(fetchCategoriesSuccess(response.data)());
  } catch {
  } finally {
    dispatch(fetchRestaurantsEnd()());
  }
};

export const createCategoriesAsync =
  (categories, data, navigate) => async (dispatch) => {
    dispatch(fetchRestaurantsStart()());
    try {
      const response = await restaurantService.createCategory(data);
      dispatch(handleNotificationAction({ title: CREATE_CATEGORY.SUCCESS })());

      const updatedCategories = [...categories, response.data];
      dispatch(fetchUpdatedCategories(updatedCategories)());
      dispatch(successfullyCreatedCategory()());
      navigate('/categories');
    } catch (error) {
      const errorMessage = error.response.data.message;

      dispatch(
        handleNotificationError(error, {
          title: errorMessage || CREATE_CATEGORY.ERROR,
        })
      );
    } finally {
      dispatch(fetchRestaurantsEnd()());
    }
  };

export const removeCategoryAsync = (id) => async (dispatch) => {
  dispatch(fetchRestaurantsStart()());
  try {
    await restaurantService.removeCategory(id);
    dispatch(
      handleNotificationAction({
        title: REMOVE_CATEGORY.SUCCESS,
        status: 'error',
      })()
    );
    dispatch(getCategoriesAsync());
  } catch (error) {
    dispatch(
      handleNotificationError(error, {
        title: REMOVE_CATEGORY.ERROR,
      })
    );
  } finally {
    dispatch(fetchRestaurantsEnd()());
  }
};

export const editCategoryAsync = (id, data, navigate) => async (dispatch) => {
  dispatch(fetchRestaurantsStart()());
  const { name } = data;
  try {
    await RestaurantService.editCategory(id, name);
    dispatch(handleNotificationAction({ title: EDIT_CATEGORY.SUCCESS })());
    dispatch(getCategoriesAsync());
    navigate(`/categories`);
  } catch (error) {
    const errorMessage = error.response.data.message;

    dispatch(
      handleNotificationError(error, {
        title: errorMessage || EDIT_CATEGORY.ERROR,
      })
    );
  } finally {
    dispatch(fetchRestaurantsEnd()());
  }
};

export const getLocationsAsync = () => async (dispatch) => {
  dispatch(fetchRestaurantsStart()());
  try {
    const response = await restaurantService.getAllLocations();
    dispatch(fetchLocationsSuccess(response.data || [])());
  } catch (error) {
    dispatch(
      handleNotificationError(error, {
        title: DECLINED_REQUEST.LOCATIONS,
      })
    );
  } finally {
    dispatch(fetchRestaurantsEnd()());
  }
};

export const createMenuItemAsync =
  (restaurantId, menuId, menuItems, data) => async (dispatch) => {
    dispatch(fetchRestaurantsStart()());
    try {
      const response = await RestaurantService.createMenuItem(
        restaurantId,
        menuId,
        data
      );
      dispatch(handleNotificationAction({ title: CREATE_MENU_ITEM.SUCCESS })());

      const updatedMenuItems = [...menuItems, response.data];
      dispatch(fetchUpdatedMenuItems(updatedMenuItems)());
      dispatch(successfullyCreatedMenuItem()());
    } catch (error) {
      const errorMessage = error.response.data.message;

      dispatch(
        handleNotificationError(error, {
          title: errorMessage || CREATE_MENU_ITEM.ERROR,
        })
      );
    } finally {
      dispatch(fetchRestaurantsEnd()());
    }
  };

export const getMenuItemsAsync = (restaurantId, menuId) => async (dispatch) => {
  dispatch(fetchRestaurantsStart()());
  try {
    const response = await restaurantService.getAllMenuItems(
      restaurantId,
      menuId
    );
    dispatch(fetchMenuItemsSuccess(response.data || [])());
  } catch (error) {
    dispatch(
      handleNotificationError(error, {
        title: DECLINED_REQUEST.MENU_ITEMS,
      })
    );
  } finally {
    dispatch(fetchRestaurantsEnd()());
  }
};

export const getMenusAsync = (restaurantId) => async (dispatch) => {
  dispatch(fetchRestaurantsStart()());
  try {
    const response = await restaurantService.getAllMenus(restaurantId);
    dispatch(fetchMenusSuccess(response.data)());
  } catch (error) {
    dispatch(
      handleNotificationError(error, {
        title: DECLINED_REQUEST.MENUS,
      })
    );
  } finally {
    dispatch(fetchRestaurantsEnd()());
  }
};

export const deleteMenuItemAsync =
  (restaurantId, menuId, menuItemId, allMenuItems) => async (dispatch) => {
    dispatch(fetchRestaurantsStart()());
    try {
      await RestaurantService.deleteMenuItem(restaurantId, menuId, menuItemId);
      dispatch(
        handleNotificationAction({
          title: DELETE_MENU_ITEM.SUCCESS,
          status: 'error',
        })()
      );
      const updatedMenuItems = allMenuItems.filter(
        (menuItem) => menuItem.menuItemId !== menuItemId
      );
      dispatch(fetchMenuItemsSuccess(updatedMenuItems)());
    } catch (error) {
      const errorMessage = error.response.data.message;

      dispatch(
        handleNotificationError(error, {
          title: errorMessage || DELETE_MENU_ITEM.ERROR,
        })
      );
    } finally {
      dispatch(fetchRestaurantsEnd()());
    }
  };

export const editMenuItemAsync =
  (restaurantId, menuId, menuItemId, data) => async (dispatch) => {
    dispatch(fetchRestaurantsStart()());
    try {
      await RestaurantService.editMenuItem(
        restaurantId,
        menuId,
        menuItemId,
        data
      );
      dispatch(handleNotificationAction({ title: EDIT_MENU_ITEM.SUCCESS })());
    } catch (error) {
      const errorMessage = error.response.data.message;

      dispatch(
        handleNotificationError(error, {
          title: errorMessage || EDIT_MENU_ITEM.ERROR,
        })
      );
    } finally {
      dispatch(fetchRestaurantsEnd()());
    }
  };

export const getRestaurantsForDateAsync = (date) => async (dispatch) => {
  dispatch(fetchRestaurantsStart()());
  try {
    const response = await RestaurantService.getRestaurantsForDate(date);
    dispatch(fetchRestaurantsForDate(response.data || [])());
  } catch (error) {
    dispatch(
      handleNotificationError(error, {
        title: DECLINED_REQUEST.RESTAURANTS_FOR_DATE,
        status: 'error',
      })
    );
  } finally {
    dispatch(fetchRestaurantsEnd()());
  }
};

export const getLimitForOrdersAsync = () => async (dispatch) => {
  dispatch(fetchRestaurantsStart()());
  try {
    const response = await RestaurantService.getLimitForOrders();
    dispatch(fetchLimitForOrders(response.data)());
  } catch (error) {
    dispatch(
      handleNotificationError(error, {
        title: DECLINED_REQUEST.LIMIT_FOR_ORDERS,
      })
    );
  } finally {
    dispatch(fetchRestaurantsEnd()());
  }
};
