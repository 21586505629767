import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';

import { currentUser } from '../../../store/currentUser/currentUser.selector';
import {
  popularOrders,
  favoriteUserOrders,
} from '../../../store/orders/orders.selector';
import {
  fetchPopularOrders,
  fetchFavoriteUserOrders,
} from '../../../store/orders/orders.thunk';
import Button from '../../atoms/Button/Button';
import PopularOrders from '../../molecules/PopularOrders/PopularOrders';

import './dashboard.scss';

const Dashboard = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector(currentUser);
  const lastName = useSelector(currentUser).userDto.lastName;
  const popularOrdersData = useSelector(popularOrders) || [];
  const favoriteUserOrdersData = useSelector(favoriteUserOrders) || [];

  useEffect(() => {
    dispatch(fetchPopularOrders());
    dispatch(fetchFavoriteUserOrders());
  }, []); // eslint-disable-line

  return (
    <div className="p-dashboard">
      <h1 className="p-dashboard__heading">
        Hi {lastName}!<span>Feeling Hungry?</span>
      </h1>
      <div className="d-flex gap-1">
        <Button
          classes="bg--blue hover-bg--black"
          text="order now!"
          onClick={() => navigate('/order')}
        />
        <Link to={`/user-profile/${user?.userDto.id}`}>
          <Button classes="bg--blue hover-bg--black" text="My orders!" />
        </Link>
      </div>

      <div className="popular-orders_container">
        {popularOrdersData.length > 0 && (
          <PopularOrders
            orderList={popularOrdersData}
            listTitle={'Top orders'}
          />
        )}

        {favoriteUserOrdersData.length > 0 && (
          <PopularOrders
            orderList={favoriteUserOrdersData}
            listTitle={'My favorite orders'}
          />
        )}
      </div>
    </div>
  );
};

export default Dashboard;
