import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';

import { INVALID_CATEGORY_NAME } from '../../../constants/notification-messages';
import { REGEX_CATEGORY_INPUT } from '../../../constants/regex-patterns';
import { categories } from '../../../store/restaurants/restaurants.selector';
import { createCategoriesAsync } from '../../../store/restaurants/restaurants.thunk';
import Button from '../../atoms/Button/Button';
import Input from '../../atoms/Input/Input';
import Form from '../../molecules/Form/Form';

import './categoryForm.scss';

const CategoryForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const allCategories = useSelector(categories);

  const handleSubmit = (data) => {
    dispatch(createCategoriesAsync(allCategories, data, navigate));
  };

  return (
    <div className="p-category-form">
      <Form onSubmit={handleSubmit} title="Add Category">
        <Input
          id="name"
          name="name"
          className="long-black"
          placeholder="name"
          required
          validation={{
            pattern: REGEX_CATEGORY_INPUT,
            patternMessage: INVALID_CATEGORY_NAME.ERROR,
            maxLength: 255,
            maxLengthMessage: INVALID_CATEGORY_NAME.LONG,
          }}
        />
        <div className="p-category-form__button-group">
          <Link to={`/categories`}>
            <Button
              icon="arrow-left"
              hoverText="go back"
              classes="hover-bg--black"
            />
          </Link>

          <Button
            classes="bg--black hover-bg--green"
            text="save"
            type="submit"
          />
        </div>
      </Form>
    </div>
  );
};

export default CategoryForm;
